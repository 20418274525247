import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';

import { ButtonWrapper } from '../../Portfolio/portfolio.style';
import { ActiveStatus } from './contact.style';
import Author from 'common/assets/image/portfolio/avatar.png';

const ContactAnfrageSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  replyWrapper,
  replyTime,
  buttonStyle,
  buttonWrapper,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Angebot erhalten" as={'h4'}/>
       <Text
            {...secDescription}
            content="Klicken Sie auf das entsprechende Formular und Sie erhalten in Kürze Ihr individuelles Angebot. Für weitere Fragen stehe ich Ihnen gerne zur Verfügung:"
          />

          <Text
            {...secDescription}
            content="0228 23 00 34"
          />
        </Box>
 <Box {...replyWrapper}>
          <ActiveStatus>
            <Image src={Author} alt="Author Avatar" />
          </ActiveStatus>
          <Heading
            as="h4"
            content="Angebotsanfragen werden priorisiert bearbeitet"
            {...replyTime}
          />
        </Box>
        <Box {...buttonWrapper}>
          <ButtonWrapper>
            <Button
              title="RA-MICRO ➡ RA-MICRO"
              className="portfolio_button"
              onClick={()=>{window.open("https://webforms.pipedrive.com/f/1C7fpMTsLLXzkZ2r67CkwTZrTMDdFvhz2LfJAGUtzXMDLEcOhlQJDk3Lut9qqXpIv","_blank")}}

              {...buttonStyle}
            />
          </ButtonWrapper>
        </Box>
        <Box {...buttonWrapper}>
          <ButtonWrapper>
            <Button
              title="ACTAPORT ➡ RA-MICRO"
              className="portfolio_button"
              target="_blank"
              onClick={()=>{window.open("https://webforms.pipedrive.com/f/1FRyKtVaGVAj5B49Y3vxAKv9dhFbXnCgHceIonXG040yUBGtZbzhfpjQfg5UG2w4H","_blank")}}
              {...buttonStyle}
            />
          </ButtonWrapper>
        </Box>

      </Container>
    </Box>
  );
};

ContactAnfrageSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  replyWrapper: PropTypes.object,
  replyTime: PropTypes.object,
  buttonStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
};

ContactAnfrageSection.defaultProps = {
  sectionWrapper: {
    pt: ['70px', '80px', '100px', '110px', '140px'],
    pb: ['70px', '80px', '100px', '110px', '140px'],
    bg: 'white',
  },
  secTitleWrapper: {
    mb: '30px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    //color: '#302b4e',
    color: '#333333',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#333333',
   // color: '#43414e',
    lineHeight: '1.5',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
    mb: '0',
  },
  replyWrapper: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyTime: {
    fontSize: '16px',
    fontWeight: '600',
    //color: '#302b4e',
    color: '#333333',
    mb: 0,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '50px',
  },
};

export default ContactAnfrageSection;
